//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "../../api/api"
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue-select/dist/vue-select.css'
import socket from "../../ws/socket"
import {mapMutations, mapState} from 'vuex'
import Loader from "../main/Loader"
import NavLinks from "../main/NavLinks"
import DocumentsHeaderBlock from "@/components/documents/headers/DocumentsHeaderBlock";
import DocumentsTable from "@/components/documents/table/DocumentsTable";
import notification from "@/notification/notification";
import RestrictedMessage from "@/components/errorBlocks/RestrictedMessage";
import ScrollToComponent from "@/components/ScrollToComponent"

export default {
  name: "Content",
  mounted() {
    this.setLimit()

    if (this.isDocuments) {
      //this.getFilters()
      if (!this.$store.state.isCentriConnected) socket.connect();
    }

    if (this.isPersonnelDocuments) {
      this.getDocuments()
    }
  },
  watch: {
    limit: function (val) {
      this.$cookies.set('limit', val)
    },
  },
  data() {
    return {
      documents: [],
      processed: null,
      signRules: {},
      signRulesEcDocs: {},

      total: 0,
      limit: 25,
      filter: {},
      countStudentDocsWithSign: false,

      DEFAULT_LIMIT: 25,
      LIMIT_CHOICES: [
        25, 50, 100
      ],
      previewLoader: false,
      documentId: '',
      restrictedError: '',
      isScrollDocTable: false
    }
  },
  computed: {
    isDocuments() {
      return this.$router.currentRoute.name === 'documents'
    },
    isPersonnelDocuments() {
      return this.$router.currentRoute.name === 'personnel_documents'
    },
    isStudentDocsWithSign() {
      if (this.countStudentDocsWithSign > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState([
      'showDocumentsLoader',
      'worker'
    ])
  },
  methods: {
    setFilter(filter) {
      this.filter = filter;
      this.filter.disabled = false;
    },

    setRestrictedError(error) {
      this.restrictedError = error
    },

    setShowPreviewLoader(isShow) {
      this.previewLoader = isShow
    },

    getDocuments(limit = null, pageNumber) {
      this.setShowDocumentsLoader(true);
      if (limit) 
        this.limit = Number(limit);

      const response = this.isDocuments
          ? api.getDocuments({ ...this.filter }, limit || this.limit, pageNumber)
          : api.getPersonnelDocuments(limit || this.limit, pageNumber)

      response.then((res) => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        } else if (res.data.error && res.data.errorStr) {
          notification.error(res.data.errorStr)
        }

        this.countStudentDocsWithSign = (res.data.result.documents && res.data.result.documents.length) || 0;

        if (res.data.result) {
          if (res.data.result.documents && Array.isArray(res.data.result.documents)) {
            this.documents = res.data.result.documents.map((doc) => ({ ...doc, checked: false }));
          } else 
            this.documents = [];
          let workerId =  this.worker.id;
          this.documents.forEach((document) => {
            document.isIAmResponsible = false;
            if (document.fromExternalEducation) {
              document.isIAmResponsible = document.studentId != this.worker.id;
            } else {
              document.signers.forEach((signer, i) => {
                if (signer.workerId == workerId && i != 0) {
                  document.isIAmResponsible = true;
                }
              });
            }
          });
          this.total = res.data.result.total;
        }
      }).finally(() => {
        this.setShowDocumentsLoader(false)
      }).catch((e) => {
        console.log(e);
      })
    },
    getSignRules() {
      this.setShowDocumentsLoader(true)
      const response = Promise.all([api.getSignRulesForType(this.filter.type), api.getSignRulesForEcDocsType(this.filter.type)]);
      response.then(([res, resEc]) => {
        console.log("res: ", res);
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        } else if (res.data.error && res.data.errorStr) {
          notification.error(res.data.errorStr)
        } else {
          this.signRules = res.data.result.rules;
        }
        if (resEc.data.error && resEc.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        } else if (resEc.data.error && resEc.data.errorStr) {
          notification.error(resEc.data.errorStr)
        } else {
          this.signRulesEcDocs = resEc.data.result.rules || [];
        }
      }).finally(() => {
        this.setShowDocumentsLoader(false)
      }).catch((e) => {
        console.log(e);
      })
    },
    changeDocument(document) {
      let docs = [];
      this.documents.forEach(el => {
        if (el.id == document.id) {
          docs.push(document);
        } else {
          docs.push(el)
        }
      })
      this.documents = docs;
    },
    setLimit(limit) {
      if (!limit) {
        limit = Number(this.$cookies.get('limit')) || this.DEFAULT_LIMIT
        this.limit = limit
      }
    },
    resetSelected() {
      this.documents.forEach(function (item) {
        item.checked = false
      })

      //this.getDocuments()
    },
    scrollToDocTable() {
      if (this.isScrollDocTable) {
        this.$refs.element.scrollTo();
      }
    },
    setScrollDocTable() {
      this.isScrollDocTable = true
    },
    ...mapMutations([
      'setShowDocumentsLoader',
      'setDocParamsToDownload'
    ]),
  },
  components: {
    RestrictedMessage,
    DocumentsTable,
    DocumentsHeaderBlock,
    NavLinks,
    Loader,
    ScrollToComponent
  }
}
